exports.components = {
  "component---src-components-blog-post-jsx": () => import("./../../../src/components/BlogPost.jsx" /* webpackChunkName: "component---src-components-blog-post-jsx" */),
  "component---src-components-shop-item-jsx": () => import("./../../../src/components/ShopItem.jsx" /* webpackChunkName: "component---src-components-shop-item-jsx" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-agb-jsx": () => import("./../../../src/pages/agb.jsx" /* webpackChunkName: "component---src-pages-agb-jsx" */),
  "component---src-pages-apps-futterlexikon-jsx": () => import("./../../../src/pages/apps/futterlexikon.jsx" /* webpackChunkName: "component---src-pages-apps-futterlexikon-jsx" */),
  "component---src-pages-apps-futterplan-jsx": () => import("./../../../src/pages/apps/futterplan.jsx" /* webpackChunkName: "component---src-pages-apps-futterplan-jsx" */),
  "component---src-pages-apps-jsx": () => import("./../../../src/pages/apps.jsx" /* webpackChunkName: "component---src-pages-apps-jsx" */),
  "component---src-pages-blog-jsx": () => import("./../../../src/pages/blog.jsx" /* webpackChunkName: "component---src-pages-blog-jsx" */),
  "component---src-pages-impressum-jsx": () => import("./../../../src/pages/impressum.jsx" /* webpackChunkName: "component---src-pages-impressum-jsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-landing-page-landing-page-jsx": () => import("./../../../src/pages/LandingPage/LandingPage.jsx" /* webpackChunkName: "component---src-pages-landing-page-landing-page-jsx" */),
  "component---src-pages-privacy-jsx": () => import("./../../../src/pages/privacy.jsx" /* webpackChunkName: "component---src-pages-privacy-jsx" */),
  "component---src-pages-shop-cart-jsx": () => import("./../../../src/pages/shop/cart.jsx" /* webpackChunkName: "component---src-pages-shop-cart-jsx" */),
  "component---src-pages-shop-jsx": () => import("./../../../src/pages/shop.jsx" /* webpackChunkName: "component---src-pages-shop-jsx" */)
}

